import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {UserProfile} from "../shared/user.model";
import {ProfileResourceService} from "../shared/profile-resoruce.service";
import {Payment} from "../../shared/payment.model";
import {AuthService} from "../../../auth/shared/auth.service";
import { env } from '../../../../../.env';
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
import { TeamResourceService } from "../../team/shared/team-resoruce.service";
import {CouponResourceService} from "../shared/coupon-resoruce.service";

declare var Stripe:any;
declare var jQuery:any;

@Component({
  selector: 'app-profile-settings',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class ProfileBillingComponent implements  OnInit
{
  teammate: any = {};
  errors: any = {};
  user: UserProfile;
  private stripe: any;
  private elements: any;
  private card: any;
  public url: string;
  cards: any[];

  brandsShort = {

    'American Express': 'cc-amex',
    'Visa': 'cc-visa',
    'MasterCard': 'cc-mastercard',
    'Discover': 'cc-discover',
    'JCB': 'cc-jcb',
    'Diners Club': 'cc-diners-club'
  };

  addCardModalId = '#add-card-modal';

  payments = [];
  triallineId: Number = 0;
  triallineIdData: Number = 0;
  mode = 'transactions';
  subscriptionCost =  59; 

  constructor(
    protected toasts: ToastrService,
    protected title: Title,
    protected route: ActivatedRoute,
    protected router: Router,
    protected ref: ChangeDetectorRef,
    protected profileResource: ProfileResourceService,
    protected auth: AuthService,
    private couponResourceService: CouponResourceService,
    private modal: Modal,
    private teamResourceService: TeamResourceService,

  ) {
    title.setTitle('Trialline | Billing');

  }

  ngOnDestroy() {
    this.ref.detach();
  }


  loadBilling(trialline_id?: number) {
    return this.profileResource.getBilling(trialline_id)
      .subscribe(
        (payments: Payment[]) => {
          this.payments = payments;
          this.mode = 'payments';
        }
      )
  }

  loadTransactions() {
    let triallineIdData = this.triallineIdData
    return this.profileResource.getTransactions(triallineIdData)
      .subscribe(
        (transitions) => {
          // @ts-ignore
          this.payments = transitions;
          this.mode = 'transactions';
        }
      )
  }

  getBrandShort(brand) {

    return this.brandsShort[brand];
  }

  ngOnInit() {
    this.route.data.subscribe((data: {user: UserProfile, cards: any}) => {
      this.user = data.user;
      this.cards = data.cards != undefined ? data.cards : {};
    });
    this.url = `${env.apiUrl}file/transactions/download/${this.user.id}/${this.triallineIdData}?token=${localStorage.getItem('jwt')}`;
    //this.loadBilling(0);
    this.loadTransactions();
  }

  billingChange(trialline_id?: number) {
    this.triallineId = trialline_id;
    this.mode = this.triallineId > 0 ? 'payments' : 'transactions';
    if (this.mode == 'payments') {
      this.loadBilling(trialline_id);
      this.url = `${env.apiUrl}file/billing/download/${trialline_id}/${this.triallineIdData}/${this.user.id}?token=${localStorage.getItem('jwt')}`;
    } else {
      this.loadTransactions();
      this.url = `${env.apiUrl}file/transactions/download/${this.user.id}/${this.triallineIdData}?token=${localStorage.getItem('jwt')}`;
    }

  }

  billingDateChange(trialline_id_data?: number) {
    this.triallineIdData = trialline_id_data;
    this.mode = this.triallineId > 0 ? 'payments' : 'transactions';
    if (this.mode == 'payments') {

      this.url = `${env.apiUrl}file/billing/download/${this.triallineId}/${this.triallineIdData}/${this.user.id}?token=${localStorage.getItem('jwt')}`;
    } else {
      this.loadTransactions();
      this.url = `${env.apiUrl}file/transactions/download/${this.user.id}/${this.triallineIdData}?token=${localStorage.getItem('jwt')}`;
    }


  }

  download() {
  //  console.log(this.url);
    window.location.replace(this.url);
  }

  deleteCard(card)
  {
    this.profileResource.deleteCard(card.id).toPromise()
      .then((response: any) => {
        // let index = this.this.cards.find(c => c.id == card.id);
        let index = this.cards.indexOf(card);
        this.cards.splice(index, 1);
        this.auth.emit();
        this.toasts.success('Card successfully removed');
        this.ref.detectChanges();
      })
      .catch((error) => {
        let context = error.error;
        if (context.error && context.error.message) {

          this.toasts.error(error.error.error.message);
        }
      });
  }

  makePrimary(card)
  {
    this.profileResource.makePrimary(card.id).toPromise()
      .then((response: any) => {
        this.auth.emit();
        this.user.card_last_four = card.last4;
        this.toasts.success('Card successfully setted up as primary');
        this.ref.detectChanges();
      })
      .catch((error) => {

        let context = error.error;
        if (context.error && context.error.message) {

          this.toasts.error(error.error.error.message);
        }
      });
  }

  async openModal() {
     
    var couponName = '';
    
    await this.couponResourceService.getSubscriptionDiscounts().toPromise()
    .then((response: any) => {
        
      if(response.length > 0) {
        response.forEach(obj => {
          if(obj.subscription == 'month-to-month') {  
            couponName = obj.coupon_name;
            
            this.freeUserCouponCode(obj.coupon_name, 59);
          }
        });
      }

    })
    .catch((error) => {
      console.log(error.err);        
    });

    
    var monthlyTeammatesCounts = 0;
    let totalTeammates = 0;
    let teammateTotalPrice = 0;
    let teammatesInfo = '';
    
    await this.teamResourceService.getTeam().toPromise()
      .then((team: any) => {
        this.teammate = team;
    });

    console.log('this.user.subscription_plan:- ', this.user.subscription_plan)
    let self = this;
    if(this.teammate.users.length > 0) {
      totalTeammates = this.teammate.users.length;
      
      for (const input of this.teammate.users) {
        teammateTotalPrice += 59;
        teammatesInfo += "["+input.email+"] Monthly Billing <br/>";
        monthlyTeammatesCounts += 1;
      }
      
      const dialogRef = this.modal.confirm()
        .size('lg')
        .isBlocking(true)
        .showClose(true)
        .keyboard(27)
        .dialogClass('modal-dialog overflowed')
        .title('Additional Subscription Charges')
        .okBtn('Yes, Proceed')
        .body("Your Subscription Cost: $"+this.subscriptionCost+"<br/>Monthly Team Members: $59 × "+monthlyTeammatesCounts+" = $"+teammateTotalPrice+"<br/>Team Members:<br/> "+teammatesInfo+"")
        .open()
      dialogRef.result
        .catch((err: any) => {
          this.modelClose()
          console.log('ERROR: ' + err)
        })
        .then((dialog: any) => {
          this.modelClose()
          if(dialog){
            jQuery(this.addCardModalId).modal('show');
          }
        });

    } else {
      const dialogRef = this.modal.confirm()
        .size('lg')
        .isBlocking(true)
        .showClose(true)
        .keyboard(27)
        .dialogClass('modal-dialog overflowed')
        .title('Subscription Confirmation')
        .okBtn('Confirm')
        .body("Your subscription will be $49 per month.<br/>Would you like to proceed?")
        .open()
      dialogRef.result
        .catch((err: any) => {
          this.modelClose()
          console.log('ERROR: ' + err)
        })
        .then((dialog: any) => {
          this.modelClose()
          if(dialog){
            jQuery(this.addCardModalId).modal('show');
          }
        });
    }

  }

  modelClose() {
    $('body').removeClass('modal-open');
  }

  freeUserCouponCode(couponvalue, planPrice) {
    let discountedAmount = 0;
    let finalAmount = 0;
    this.couponResourceService.getCouponByName(couponvalue).toPromise()
      .then((response: any) => {
        if(planPrice == 59) {
          if(response.body.discount_type == 'percent_off') {
            discountedAmount =  planPrice * response.body.coupon.percent_off / 100;
            this.subscriptionCost = planPrice - discountedAmount;
          } else {
            discountedAmount =  response.body.coupon.amount_off;
            this.subscriptionCost = planPrice - discountedAmount;
          }
        }
      })
      .catch((error) => {
          console.log('error: ', error);
      });

  }

  cardAdded(card) {
    jQuery(this.addCardModalId).modal('hide');
    this.cards.push(card);
    if (this.cards.length == 1) {

      this.user.card_last_four = card.last4;
      this.user.card_brand = card.brand;
    }
    this.auth.emit();
    this.makePrimary(card);
    this.ngOnInit();
    this.ref.detectChanges();
  }

  getDescription(payment) {
    if (this.mode == 'payments') {
      return payment.trialline ? payment.trialline.name : '';
    }
    const subName = payment.subscription_name;
    const subPlan = payment.subscription_plan;
    if (!subName || !subPlan) {
      return '';
    }
    return `${subName} - ${subPlan.replace('_tl', '')}`;
  }

  // getCardData(form: NgForm) {
  //   let dataObj = {
  //     number: form.value.number,
  //     exp_month: form.value.exp_month,
  //     exp_year: form.value.exp_year,
  //     cvc: form.value.cvc
  //   };
  //
  //   Stripe.card.createToken(dataObj,
  //     (status, response) => {
  //       if (status === 200) {
  //         let token = response.id;
  //
  //         this.updateCard(token);
  //       }
  //       else {
  //
  //         this.toasts.error(response.error.message);
  //       }
  //     }
  //   );
  // }
}
