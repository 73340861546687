<div class="content">
  <form #loginForm="ngForm" class="login-form" (submit)="login(loginForm)" novalidate>
    <h3 class="form-title" style="font-family: 'Open Sans', sans-serif !important;">Login to your account</h3>
    <div class="alert alert-danger display-hide">
      <button class="close" data-close="alert"></button>
      <span style="font-size: 14px;"> Enter your personal details below: </span>
    </div>
    <div class="form-group" [hubValidator]="errors?.email" transformer="inline">
      <label class="control-label visible-ie8 visible-ie9">Email</label>
      <div class="input-icon">
        <i class="fa fa-user"></i>
        <input class="form-control" [ngModel] type="text" placeholder="Email" name="email" autocomplete="off"></div>
    </div>
    <div class="form-group" [hubValidator]="errors?.password" transformer="inline">
      <label class="control-label visible-ie8 visible-ie9">Password</label>
      <div class="input-icon">
        <i class="fa fa-lock"></i>
        <input class="form-control" [ngModel] type="password" placeholder="Password" name="password" autocomplete="off">
      </div>
    </div>
    <div *ngIf="showOTP" class="form-group" transformer="inline">
      <label class="control-label visible-ie8 visible-ie9">OTP</label>
      <div class="input-icon">
        <i class="fa fa-key"></i>
        <input class="form-control" type="text" placeholder="Enter OTP" [(ngModel)]="otp" autocomplete="off" pattern="[0-9]{6}" maxlength="6" name="secure_otp" id="secure_otp" />
        <label style="color: red; font-size: smaller; margin-left: 10px;">OTP required. Please enter OTP sent to your email.</label>
        <button [disabled]="otp.length !== 6" type="button" class="btn blue pull-right" style="margin-top: 10px;" (click)="verifyOTP(loginForm)">Login</button>
      </div>
    </div>
    <div class="form-group" transformer="inline">
      <div class="input-icon" id="example-container"></div>
    </div>
    <div *ngIf="!showOTP" class="form-actions">
      <label class="rememberme mt-checkbox mt-checkbox-outline">
        <input type="checkbox" name="remember" value="1"> Remember me
        <span></span>
      </label>
      <button [disabled]="processing" type="submit" class="btn blue pull-right">Login</button>
      <img *ngIf="processing" alt="spinner" src="assets/squares.gif" width="20px">
    </div>
    <div class="forget-password">
      <h4 style="font-family: 'Open Sans', sans-serif;"><a  (click)="dialogUser()" >Trouble logging in?</a>
      </h4>
      <p style="font-family: 'Open Sans', sans-serif !important; font-size: 14px;"> No worries, click  <a  (click)="dialogUser()" >here</a> for help. </p>
    </div>
    <div class="create-account">
      <p style="font-family: 'Open Sans', sans-serif !important; font-size: 14px;"> Don't have an account yet?&nbsp;
        <a [routerLink]="['/register']" id="register-btn"> Create an account.</a>
      </p>
    </div>
  </form>
</div>


<!-- Create New Send Model -->
<div  *ngIf='divStatus' id="create-user-dialog" style="
    z-index: 9999999;
    overflow: visible;
     position: absolute;
     top: 50%;
     margin-right: -50%;
     transform: translate(-50%, -50%);
    left: 50%;">
  <div class="modal-content">
    <div class="modal-body">
      <form id="notificationForm" #notificationForm="ngForm" (submit)="userSend(notificationForm)" class="register-form error" novalidate
            style="display: block; ">
        <h3 style="font-family: 'Open Sans', sans-serif !important;">What would you like to do?</h3>
        <p style="font-family: 'Open Sans', sans-serif !important; font-size: 14px;"> Please enter your email address: </p>
        <div class="form-group" [hubValidator]="errorEmail" transformer="inline">
          <div class="input-icon">
            <i class="fa fa-envelope"></i>
            <input class="form-control" type="text" placeholder="Email" [ngModel] name="email">
            <span class="help-block" *ngIf="errorEmail" style="color: red;">{{ errorMess }}</span>
          </div>
        </div>
        <div class="form-actions">
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-default" id="reset-password" type="submit" (click)="resetPassword(notificationForm)">
        RESET PASSWORD
      </button>
      <button class="btn btn-default" id="activation-email" type="submit" (click)="activationEmail(notificationForm)">
        RESEND ACTIVATION EMAIL
      </button>
      <button data-dismiss="modal" class="btn btn-primary" (click)="cancel()">
        Cancel
      </button>
    </div>
  </div><!-- /.modal-content -->
</div>
