<div id="event-edit-modal" class="modal thtyty" role="dialog" style="z-index: 1400;overflow-y: auto;"
     (keyup)='getkeyvalue($event)'>
  <div class="modal-dialog modal-lg" id="ss">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" id='close' data-dismiss="modal" aria-label="Close"
                (click)="closeAddFilesModal()" (close)="closeAddFilesModal()"><span
          aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">{{ (event.id ? '' : 'Create new event ') + (event.title ? event.title : '') }}</h4>
      </div>
      <form #eventForm="ngForm" role="form-horizontal" class="event-form" (submit)="saveEvent(eventForm);">
        <input type="hidden" name="selecteddateformat" [ngModel]="selectedDateFormat" />
        <div class="modal-body portlet-body">
          <div class="panel-group" id="accordion">
            <div class="panel panel-default">
              <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" data-target="#general"
                   (click)='showaccordian(1)'>
                <h4 class="panel-title">
                  <a class="accordion-toggle">
                    GENERAL</a>
                </h4>
              </div>
              <div id="general" class="panel-collapse collapse in">
                <div class="panel-body">
                  <div class="form-body form-date ">
                    <div class="form-group">
                      <label>Title</label>
                      <input type="text" name="title" [ngModel]="event.title" class="form-control"
                             #title="ngModel" placeholder="Enter title here .." autocomplete="off">
                    </div>
                    <div class="row margin-reset">
                      <div class="col-md-8 tab-content-left-col">
                        <div class="form-group">
                          <label>Start Date*</label>
                          <div class="form-inline">
                            <div class="input-group date col-md-4 date-picker-date-from">
                              <!--<input type="text" class="form-control" name="start_date">-->
                              <custom-datetime [timepicker]="false"
                                               [datepicker]="{maxViewMode: 2, minViewMode: 1, format: displayDateFormat?.halfDateFormat, autoclose:true, halfformat:true}"
                                               *ngIf="displayDateFormat && if_start_days.checked"
                                               [ngModel]="event.date_start"
                                               name="start_date"
                                               required>
                              </custom-datetime>
                              <custom-datetime [timepicker]="false"
                                               [datepicker]="{maxViewMode: 2, format: displayDateFormat?.fullDateFormat, autoclose:true, halfformat:false}"
                                               [ngModel]="event.date_start"
                                               name="start_date"
                                               *ngIf="displayDateFormat && !if_start_days.checked"
                                               required>
                              </custom-datetime>
                            </div>
                            <div class="input-group">
                              <custom-datetime [timepicker]="{showSeconds: true, showMeridian: true}"
                                               [datepicker]="false" 
                                               [ngModel]="event.date_start"
                                               name="start_time" 
                                               *ngIf="displayDateFormat && event.has_times">
                              </custom-datetime>
                            </div>
                            <div *ngIf="!valid">
                                <span class="help-block" *ngIf="!eventForm.form.controls.start_date.valid"
                                      style="color: red;">
                                  Start Date is required
                                </span>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="mt-checkbox-inline">
                            <label class="mt-checkbox">
                              <input name="if_start_days" [ngModel]="event.if_start_days"
                                     #if_start_days type="checkbox">
                              Show only year and month
                              <span></span>
                            </label>
                          </div>
                          <a  class="btn btn-default btn-sm" 
                              id="btnTimes"
                              (click)="$event.preventDefault(); switchHasTimes()"
                              
                             >
                            {{ event.has_times ? 'Remove' : 'Add' }} Start Time
                          </a>
                        </div>
                        <input type="hidden" [ngModel]="event.has_times" name="has_times">
                        <input type="hidden" [ngModel]="event.has_times_end" name="has_times_end">
                        <div *ngIf="event?.end_date != null">
                          <div class="form-group">
                            <label>End Date</label>
                            <div class="form-inline">
                              <div class="input-group date col-md-4 date-picker-date-from">
                                <custom-datetime  [timepicker]="false" 
                                                  [ngModel]="event.date_end"
                                                  [datepicker]="{maxViewMode: 2, minViewMode: 1, format: displayDateFormat?.halfDateFormat, autoclose:true, halfformat:true}"
                                                  *ngIf="displayDateFormat && if_end_days.checked"
                                                  name="end_date">
                                </custom-datetime>
                                <custom-datetime  [timepicker]="false" 
                                                  [ngModel]="event.date_end"
                                                  [datepicker]="{maxViewMode: 2, format: displayDateFormat?.fullDateFormat, autoclose:true, halfformat:false}"
                                                  *ngIf="displayDateFormat && !if_end_days.checked"
                                                  name="end_date">
                                </custom-datetime>
                              </div>
                              <div class="input-group">
                                <custom-datetime [timepicker]="{showSeconds: true, showMeridian: true}"
                                                 [datepicker]="false" 
                                                 [ngModel]="event.date_end" 
                                                 name="end_time"
                                                 *ngIf="displayDateFormat && event.has_times_end"></custom-datetime>
                              </div>
                              <!-- <div *ngIf="lesserEndDate">
                                <span class="help-block" 
                                      style="color: red;">
                                 End Date Should be greater than Start Date
                                </span>
                            </div> -->
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="mt-checkbox-inline">
                              <label class="mt-checkbox">
                                <input name="if_end_days" [ngModel]="event.if_end_days"
                                       #if_end_days type="checkbox">
                                Show only year and month
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="input-group date">
                          <a id="btnAdditional" class="btn btn-default btn-sm" style="margin-right: 3px"
                            (click)="$event.preventDefault(); switchEndDate()">
                            {{ event.end_date ? 'Remove' : 'Add' }} End Date
                          </a>
                          <a class="btn btn-default btn-sm" id="btnTimes_end" *ngIf="event?.end_date"
                            (click)="$event.preventDefault(); switchHasTimesEnd()">
                            {{ event.has_times_end ? 'Remove' : 'Add' }} End Time
                          </a>
                        </div>
                        <br>
                        <div>
                          <div class="form-group">
                            <label>Category*</label>
                            <div class="form-inline">
                              <div class="input-group date col-md-5">
                                <select #category (change)="categoryChanged(category.value)"
                                        [ngModel]="event?.category_id" name="category_id" class="form-control"
                                        required>
                                  <option [attr.value]="0">Choose category</option>
                                  <option *ngFor="let category of categories" [attr.value]="category.id"
                                          [attr.selected]="event.category_id == category.id ? true : null">
                                    {{ category.name }}
                                  </option>
                                </select>
                              </div>
                              <div *ngIf="!valid">
                                <span class="help-block" *ngIf="!categoryValid" style="color: red;">
                                  Category is required
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <app-trialline-event-modal-categories [triallineId]="triallineId"
                                                                  (onCreated)="addCategory($event)">
                            </app-trialline-event-modal-categories>
                          </div>
                          <h5>Reference</h5>
                          <div class="row">
                            <div class="col-sm-3">
                              <div class="form-group">
                                <input type="text" name="bates_number" [ngModel]="event.bates_number"
                                       class="form-control" id="bates_number"
                                       placeholder="Bates Page Number">
                              </div>
                            </div>
                            <div class="col-sm-3">
                              <div class="form-group">
                                <input type="text" name="exhibit_number" [ngModel]="event.exhibit_number"
                                       class="form-control" id="exhibit_number"
                                       placeholder="Exhibit Number">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 tab-content-left-col">
                        <div class="form-group">
                          <div class="note-small mb-1">Click on image to change the thumbnail</div>
                          <label for="thumbnail_input">
                            <input type="file" ng2FileSelect [uploader]="uploader" id="thumbnail_input"
                                   style="display: none;">
                            <div style="margin-top: 10px">
                              <div class="fileinput-preview thumbnail tile upload-files"
                                   style="width: 210px; position: relative;">
                                <div class="tile-body">
                                  <img #thumbnail [attr.src]="event.thumbnail_encryptid
                                   && event.thumbnail_encryptid  !== null ?
                                  publicFolderUrl +'index.php/v1/filesystem/'+(event.thumbnail_encryptid)+'?preview=1&date='+event.updated_at?.replaceAll(' ', '').replaceAll(':', '').replaceAll('-', ''):
                                  (publicImages + (event.thumbnail_attr ?
                                   event.thumbnail_attr.substring(1) : defaultThumbnail.substring(1)))"
                                       class="img-responsive" width="200px" alt="thumbnail">
                                  <a (click)="deleteThumbnail(thumbnail.src); $event.preventDefault()"
                                     id="delete-background" class="remove-background">&times;</a>
                                </div>
                              </div>
                            </div>
                          </label>
                          <div class="progress" style="height: 3px; width: 200px; margin: 0">
                            <div class="progress-bar"  *ngIf="progressBarUploder" role="progressbar" [style.width]="uploader.progress + '%'"></div>
                          </div>
                          <div class="form-group" style="margin-top: 10px;">
                            <div class="mt-checkbox-inline">
                              <label class="mt-checkbox">
                                <input name="thumbnail_visible" id='thumbnail_visible' [ngModel]="event.thumbnail_visible"
                                       #visibility type="checkbox">
                                Show thumbnail
                                <span></span>
                              </label>
                            </div>
                            <div class="upload-container" *ngIf="visibility.checked">
                              <div>
                                <button (click)="$event.preventDefault(); openthumbnailFromLibraryModal($event); false"
                                        (mouseenter)='Test();' (mouseleave)='mouseleavefunction()'
                                        class="btn btn-primary" style="margin-top:-8px;">Add Thumbnail From Existing
                                  TrialLine Files
                                </button>
                              </div>
                            </div>
                          </div>
                          <div style="width: 30%; margin-top: 10px" *ngIf="!isCropped">
                            <div style="display: flex; flex-direction: row;">
                              <button type="button" class="btn btn-success" *ngIf="event.thumbnail_encryptid"
                                      (click)="showCropImage()">Crop Image</button>
                              <img src="assets/squares.gif" class="img spinners" alt="spinner" width="30px"
                                   id="processing" *ngIf="cropProcessing">
                            </div>
                          </div>
                          <div id="crop_block">
                            <div style="display: flex; flex-direction: row; margin-bottom: 10px">
                              <button style="display: flex;" type="button" (click)="cropApply()" class="btn btn-success">
                                Apply
                              </button>
                              <button style="display: flex; margin-left: 2rem" type="button" (click)="cropCancel()"
                                      class="btn btn-danger">
                                Cancel
                              </button>
                            </div>
                            <img class="mr-auto p-2" [attr.src]="cropped_image_src" style="display: block; max-width: 100%;" alt="" id="crop_img">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group" style="z-index: 1;">
                      <label>Description</label>
                      <ckeditor (change)="onCKChange($event)" [(ngModel)]="event.description" name="description"
                                id="editor1" debounce="500" [config]="{ versionCheck: false }" #ckeditor
                                editorUrl="https://cdn.ckeditor.com/4.5.11/full-all/ckeditor.js"
                      >
                      </ckeditor>
                      <div id="triallinemodal" class="modal fade " role="dialog"
                           style="display: block; position: absolute;left: 50%;top:70%;height:100%;transform: translate(-50%, -50%);"
                           [style.visibility]="status1 ? 'visible' : 'hidden'">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <button type="button" class="close" (click)="hidemodal()" style="font-size:xx-large;">
                                &times;
                              </button>
                              <h4 class="modal-title" style="text-align: center;">Select TrialLine To Link To</h4>
                            </div>
                            <div class="modal-body" style="max-height: 20pc;overflow-y: auto;overflow-x: hidden;">
                              <div class="container">
                                <ul class="menu" style="list-style: none">
                                  <li *ngFor="let trialline of triallines;let i = index" value="{{ trialline.id }}">
                                    <a (click)="getTriallineLink(trialline.id, trialline.name)"
                                       title="Click here to link that TrialLine">{{ trialline.name }}</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="add-thumbnail-from-library-modal" class="modal fade_ bs-modal-lg in_ overflowed_ in"
                   role="dialog"
                   style="z-index:1600;" data-modal-parent="#event-edit-modal">
                <div class="modal-dialog" style="width: 90%;">
                  <div class="modal-content">
                    <div class="modal-header ">
                      <div class="row">
                        <div class="col-sm-4">
                          <h4 class="modal-title">Add Thumbnail From File Library</h4>
                        </div>
                        <div class="col-sm-7">
                        </div>
                        <div class="col-sm-1">
                          <button (click)="closePreviewer1()" type="button" class="close"><span
                            aria-hidden="true">&times;</span></button>
                        </div>
                      </div>
                    </div>
                    <img src="assets/squares.gif" class="img spinners center-block" alt="spinner" *ngIf="processing">
                    <div class="modal-body" style="max-height: calc(100vh - 100px);overflow-y: auto;">
                      <div class="row">
                        <div *ngIf='thumbnailbackfolder != null && thumbnailbackfolder != undefined && thumbnailbackfolder.length>0' class="col-md-4" style="margin-top: 1pc;"
                             title="click here to go up">
                          <a (click)="thumbnaillevelup()"> <i class="fa fa-folder-open"
                                                              style="font-size: 24px;color: #034375;"></i>&nbsp;&nbsp;<span
                            style="font-size: 16px;font-family: sans-serif;font-weight: bold;">
                           <i class="fa fa-ellipsis-h"></i>
                         </span></a>
                        </div>
                        <div *ngFor="let nefo of ttfolder">
                          <div class="col-md-4" style="margin-top: 1pc;">
                            <div class="row">
                              <div class="col-sm-12 col-md-12">
                                <a (click)="OpenTrlFolderthumbnail(nefo)"> <i class="fa fa-folder-open"
                                                                              style="font-size: 24px;color: #034375;"></i>&nbsp;&nbsp;<span
                                  style="font-size: 16px;font-family: sans-serif;font-weight: bold;">
                                         {{nefo}}
                                       </span></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <hr *ngIf='ttfolder.length>0 && thumbnailfilesarray.length>0'
                            style="height:1px;border:none;color:#868383;background-color:#a9a7a7;"/>
                      </div>
                      <div class="row">
                        <div class="tiles upload-files no-preview">
                          <ul>
                            <li *ngFor="let file of thumbnailfilesarray" class="tile image"
                                (click)="selectFileeventthumbnail(file)"
                                style="margin-left: 4px;border: 4px solid;color: #f3f3f3;">
                              <div class="tile-body">
                                <div class="upload-file-img"
                                     title="{{ getFileName(file.title) }}"
                                     [style.background-image]="'url(' + apiPath + 'filesystem/' + file.encrypt_id + '?preview=1)'"
                                >
                                </div>
                                <div class="tile-object">
                                  <div class="name"
                                       style="width: 127px;overflow: hidden;  line-break: anywhere;">{{getFileName(file.title)}}</div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" data-target="#provider-details"
                   (click)='showaccordian(2)'>
                <h4 class="panel-title">
                  <a class="accordion-toggle">
                    PROVIDER DETAILS
                  </a>
                </h4>
              </div>
              <div id="provider-details" class="panel-collapse collapse">
                <div class="panel-body">
                  <div class="form-body">
                    <div class="form-horizontal">
                      <h5>Provider Details</h5>
                      <div class="form-group">
                        <label for="providerName" class="col-sm-3 control-label">Provider Name</label>
                        <div class="col-sm-9">
                          <input type="text" name="provider_name" [ngModel]="event.provider_name" class="form-control"
                                 id="providerName" placeholder="Name">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="providerPhone" class="col-sm-3 control-label">Provider Phone</label>
                        <div class="col-sm-9">
                          <input type='text' name="provider_phone" [ngModel]="event.provider_phone"
                                 class="form-control" id="providerPhone" placeholder="Phone">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="providerEmail" class="col-sm-3 control-label">Provider Email</label>
                        <div class="col-sm-9">
                          <input type="email" name="provider_email" class="form-control"
                                 id="providerEmail"
                                 [ngModel]="event.provider_email"
                                 placeholder="Email">
                        </div>
                      </div>
                      <h5>Facility Location Details</h5>
                      <div class="form-group">
                        <label for="facilityName" class="col-sm-3 control-label">Facility Name</label>
                        <div class="col-sm-9">
                          <input type="text" name="facility_name" [ngModel]="event.facility_name"
                                 class="form-control" id="facilityName" placeholder="Facility Name">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="facilityAddress" class="col-sm-3 control-label">Address</label>
                        <div class="col-sm-9">
                          <input type="text" name="address" [ngModel]="event.address" class="form-control"
                                 id="facilityAddress" placeholder="Address">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="facilityAddress" class="col-sm-3 control-label">Office number</label>
                        <div class="col-sm-9">
                          <input type="number" name="office_number" [ngModel]="event.office_number" class="form-control"
                                 id="facilityOfficeNumber" placeholder="Office number">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="facilityAddress" class="col-sm-3 control-label">City</label>
                        <div class="col-sm-9">
                          <input type="text" name="city" [ngModel]="event.city" class="form-control" id="facilityCity"
                                 placeholder="City">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="facilityAddress" class="col-sm-3 control-label">State</label>
                        <div class="col-sm-9">
                          <input type="text" name="state" [ngModel]="event.state" class="form-control"
                                 id="facilityState" placeholder="State">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="facilityAddress" class="col-sm-3 control-label">Postal code</label>
                        <div class="col-sm-9">
                          <input name="postal_code" [ngModel]="event.postal_code" class="form-control"
                                 id="facilityPostalCode"
                                 type="text"
                                 placeholder="Postal code">
                        </div>
                      </div>
                      <h5>Attach Files</h5>
                      <div class="attach-wrap">
                        <input type="file" ng2FileSelect [uploader]="privateUploader" multiple/><br/>
                        <table class="table">
                          <thead>
                          <tr>
                            <th>Attach</th>
                            <th>Progress</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngFor="let file of event?.private_files">
                            <td>
                              <input class="form-control input-sm" type="text" disabled
                                     [attr.value]="file.file.title" placeholder="Attach Text">
                            </td>
                            <td>
                              <span><i class="glyphicon glyphicon-ok"></i></span>
                            </td>
                            <td nowrap>
                              <a [attr.href]="apiPath + 'filesystem/' + file.file.encrypt_id + '/download'"
                                 class="btn btn-success btn-xs">
                                <span class="glyphicon glyphicon-download"></span> Download
                              </a>
                              <a (click)="removeFile(file.file)"
                                 class="btn btn-danger btn-xs">
                                <span class="glyphicon glyphicon-remove"></span> Delete
                              </a>
                            </td>
                          </tr>
                          <tr *ngFor="let item of privateUploader.queue">
                            <td>
                              <input class="form-control input-sm" type="text" #attachText
                                     [disabled]="item.isReady || item.isUploading || item.isSuccess">
                            </td>
                            <td>
                              <div class="progress" style="margin-bottom: 0;">
                                <div class="progress-bar" role="progressbar"
                                     [ngStyle]="{ 'width': item.progress + '%' }"></div>
                              </div>
                              <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
                            </td>
                            <td nowrap>
                              <button type="button" class="btn btn-success btn-xs"
                                      (click)="upload(item, attachText.value)"
                                      [disabled]="item.isReady || item.isUploading || item.isSuccess">
                                <span class="glyphicon glyphicon-upload"></span> Upload
                              </button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" data-target="#add-contact"
                   (click)='showaccordian(3)'>
                <h4 class="panel-title">
                  <a class="accordion-toggle">ADD CONTACT</a>
                </h4>
              </div>
              <div id="add-contact" class="panel-collapse collapse">
                <div class="panel-body">
                 <app-trialline-event-modal-contacts [contactEvent]="event"
                                                     [contacts]="event?.contacts"
                                                     [triallinesId]="triallineId"
                                                     (onAddContact)="addContact($event)"
                                                     (onAddTag)="addtag($event)"
                                                     (onRemoveContact)="removeContact($event)">
                 </app-trialline-event-modal-contacts>
                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" data-target="#add-bill-cost"
                   (click)='showaccordian(7)'>
                <h4 class="panel-title">
                  <a class="accordion-toggle">BILLS & COSTS</a>
                </h4>
              </div>

              <div id="add-bill-cost" class="panel-collapse collapse">
                <div class="panel-body">
                  <input type="file" class="8888" ng2FileSelect [uploader]="billCostUploader"
                         (onFileSelected)="fileSelected($event)" multiple/><br/>
                  <table class="table">
                    <thead>
                    <tr>
                      <th>File name</th>
                      <th>Amount</th>
                      <th>Bill/Cost</th>
                      <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let file of event?.bill_cost_files">
                      <td>
                        <input class="form-control input-sm" type="text"
                               (change)="changeBillCostFilesAttached($event, file)" value="{{file.file.title}}"
                               placeholder="Attach Text">
                      </td>
                      <td>
                        <input class="form-control input-sm" type="number"
                               (change)="changeBillCostFilesAmount($event, file)" value="{{file.amount}}"
                               placeholder="Amount">
                      </td>
                      <td>
                        <select class="form-control input-sm" (change)="changeBillCostFilesType($event, file)">
                          <option value="bill" [selected]="file.bill_cost == 'bill'">Bill</option>
                          <option value="cost" [selected]="file.bill_cost == 'cost'">Cost</option>
                        </select>
                      </td>
                      <td nowrap>
                        <a [attr.href]="apiPath + 'filesystem/' + file.file.encrypt_id + '/download'"
                           class="btn btn-success btn-xs">
                          <span class="glyphicon glyphicon-download"></span> Download
                        </a>
                        <a (click)="removeFile(file.file)"
                           class="btn btn-danger btn-xs">
                          <span class="glyphicon glyphicon-remove"></span> Delete
                        </a>
                      </td>
                    </tr>
                    <tr *ngFor="let item of billCostFiles">
                      <td>
                        <input class="form-control input-sm" type="text"
                               (change)="changeBillCostFilesAttached2($event, item)"
                               value="{{item.some.name}}">
                      </td>
                      <td>
                        <input class="form-control input-sm" type="text" value="{{item.amount}}"
                               (change)="changeBillCostFilesAmount2($event, item)" placeholder="Amount">
                      </td>
                      <td>
                        <select class="form-control input-sm" (change)="changeBillCostFilesType2($event, item)">
                          <option value="bill" [selected]="item.bill_cost == 'bill'">Bill</option>
                          <option value="cost" [selected]="item.bill_cost == 'cost'">Cost</option>
                        </select>
                      </td>
                      <td nowrap>
                        <a [attr.href]="apiPath + 'filesystem/' + item.file.file.encrypt_id + '/download'"
                           class="btn btn-success btn-xs">
                          <span class="glyphicon glyphicon-download"></span> Download
                        </a>
                        <a (click)="removeFile(item.file)"
                           class="btn btn-danger btn-xs">
                          <span class="glyphicon glyphicon-remove"></span> Delete
                        </a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" data-target="#add-media"
                   (click)='showaccordian(4)'>
                <h4 class="panel-title">
                  <a class="accordion-toggle">ADD MEDIA</a>
                </h4>
              </div>
              <div id="add-media" class="panel-collapse collapse">
                <div class="panel-body">
                  <div class="upload-box">
                    <app-shared-files [moreFiles]="moreFiles" #sharedFiles [files]="event.files"
                                      (onRemove)="removeFile($event)"
                                      (onChangeContacts)="changeContacts($event)"
                                      (onCloud)="cloudFile($event)" [preview]="true"></app-shared-files>
                    <div class="">
                      <div class="col-md-8">
                        <app-shared-dropzone (onSuccess)="addFile($event)" [triallineId]="this.triallineId"
                                             (onQueueChanged)="handleDropzoneQueue($event)">
                        </app-shared-dropzone>
                      </div>
                      <div class="col-md-4">
                        <div class="upload-container" style="padding:9px;">
                          <div style="height: 141px;">
                            <button (click)="$event.preventDefault(); openFromLibraryModal()"
                                    class="btn btn-lg btn-primary" style="margin-top: 45px;">Add From Existing TrialLine
                              Files
                            </button>
                            <app-shared-from_library *ngIf="fromLibraryModal" [triallineId]="triallineId"
                                                     [existFileIds]="exceptFileIds" (onSelected)="addFile($event)">
                            </app-shared-from_library>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="upload-video-box">
                    <app-shared-videos [videos]="event.videos" [preview]="true"
                                       (onRemove)="deleteVideo($event)"></app-shared-videos>
                  </div>
                  <div class="input-group">
                    <input #videoUrl type="text" class="form-control" placeholder="YouTube video url...">
                    <span class="input-group-btn">
                    <button (click)="uploadVideo(videoUrl.value); videoUrl.value = ''" class="btn btn-default"
                            type="button">Add</button>
                  </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" data-target="#add-tag"
                   (click)='showaccordian(5)'>
                <h4 class="panel-title">
                  <a class="accordion-toggle">ADD TAGS</a>
                </h4>
              </div>
              <div id="add-tag" class="panel-collapse collapse">
                <div class="panel-body">
                  <div class="row">
                    <div class="col-sm-4">
                      <input list="filteredArray" (keyup)="getparticulartag($event)" type="text" class="form-control"
                             id='tag' autocomplete="off" placeholder="Type tags here..." #tag_text>
                      <datalist id="filteredArray" *ngIf='searchtag != null && searchtag.length >= 1'>
                        <option (keyup.enter)='addtag(tag.tag_name)' (click)='addtag(tag.tag_name)'
                                *ngFor="let item of searchtag">
                                {{ item.tag_name.length > 10
                                  ? item.tag_name.substr(0, 10)+'...' : item.tag_name | uppercase}}
                                </option>
                      </datalist>
                    </div>
                    <div class="col-sm-1">
                      <div style="height:11px;margin-left:-12px;">
                        <button (click)="$event.preventDefault();eventtags(tag_text.value)"
                                class="btn btn-primary">Add Tag
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-left:2px;margin-top: 10px;">
                      <span *ngFor="let tag of eventtagslist">
                        <a class="btn btn-default btn-outline btn-event" style="margin-top: 7px;">
                          {{ tag.tag_name.length > 10
                            ? tag.tag_name.substr(0, 10)+'...' : tag.tag_name | uppercase}}
                          &nbsp;<i class="fa fa-remove" style="color: #7e878e;" (click)='removetag(tag)'></i>
                        </a>
                      </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" data-target="#notes"
                   (click)='showaccordian(6)'>
                <h4 class="panel-title">
                  <a class="accordion-toggle">
                    NOTES
                  </a>
                </h4>
              </div>
              <div id="notes" class="panel-collapse collapse">
                <div class="panel-body">
                  <div class="form-body form-date ">
                    <div class="form-group">
                      <label>Notes</label>
                      <!-- <ckeditor  #ckeditor1 [ngModel]="event.notes" name="notes" id="editor2" [config]="config" debounce="500">
                      </ckeditor> -->
                      <ckeditor  #ckeditor1 [ngModel]="event.notes" name="notes" id="editor2" [config]="{ versionCheck: false }" debounce="500">
                      </ckeditor>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal" (click)="closeModal()" >Close</button>
          <button [disabled]="! saveAvailable" type="submit" class="btn btn-primary">Save changes</button>
          <a *ngIf="event.id"
             class="btn red btn-delete pull-left"
             mwlConfirmationPopover
             [title]="'Are You Sure?'"
             [popoverMessage]="'Warning! If you delete this Event you won\'t be able to retrieve it. Are you sure you want to proceed?'"
             placement="top"
             (confirm)="deleteEvent()">
            Delete
          </a>
          <a *ngIf="event.id"
             class="btn btn-primary pull-left"
             mwlConfirmationPopover
             [title]="'Are You Sure?'"
             [popoverMessage]="'You are about to duplicate this event. You will be able to make any required changes to the duplicated event. Remember to save your changes once you are finished to add this event to your TrialLine.'"
             [confirmText] ="'Got it.'"
             placement="top"
             (confirm)="eventCopy()">
            Copy event
          </a>
        </div>
      </form>
    </div>
  </div>
</div>
