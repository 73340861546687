import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TriallinesComponent} from "./triallines/triallines.component";
import {MainComponent} from "../layouts/main/main.component";
import {TriallinesResolver} from "./triallines/shared/triallines.resolver";
import {ProfileResolver} from "./profile/shared/profile.resolver";
import {ProfileSettingsComponent} from "./profile/settings/settings.component";
import {ProfileBillingComponent} from "./profile/billing/billing.component";
import {SupportComponent} from "./support/support.component";
import {ProfileClioComponent} from "./profile/clio/clio.component";
import {FileLibraryComponent} from "./file-library/file-library.component";
import {FilesResolver} from "./file-library/shared/files.resolver";
import {ProfileSubscriptionComponent} from "./profile/subscription/subscription.component";
import {ManageComponent} from "./team/manage/manage.component";
import {TeamManageResolver} from "./team/shared/team-manage.resolver";
import {NotLowCanActivate} from "../auth/shared/low-plan.guard";
import {CardsResolver} from "./profile/shared/cards.resolver";
import {ApplicationComponent} from "./application/application.component";
import {TriallineModule} from "./trialline/trialline.module";
import {UserTypeGuestCanActivate} from "../auth/shared/user_type_guest.guard";


const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      // Dashboard
      { path: 'dashboard', component: TriallinesComponent, resolve: { triallines: TriallinesResolver } },

      // Profile settings
      { path: 'profile/settings', component: ProfileSettingsComponent, resolve: { user: ProfileResolver } },
      { path: 'profile/billing',
        component: ProfileBillingComponent,
        resolve: { user: ProfileResolver, cards: CardsResolver },
        canActivate: [ UserTypeGuestCanActivate ]
      },
      { path: 'profile/clio',
        component: ProfileClioComponent,
        resolve: { user: ProfileResolver},
        canActivate: [ UserTypeGuestCanActivate ]
      },
      { path: 'profile/subscription',
        component: ProfileSubscriptionComponent,
        resolve: { user: ProfileResolver },
        canActivate: [ UserTypeGuestCanActivate ]
      },
      { path: 'profile/subscription/v2',
        component: ProfileSubscriptionComponent,
        resolve: { user: ProfileResolver },
        canActivate: [ UserTypeGuestCanActivate ]
      },


      // Support
      { path: 'support', component: SupportComponent, resolve: { triallines: TriallinesResolver } },

      // Desktop application
      { path: 'desktop', component: ApplicationComponent, canActivate: [ UserTypeGuestCanActivate ]},

      // File library
      { path: 'file/library',
        component: FileLibraryComponent,
        resolve: { files: FilesResolver },
        canActivate: [ UserTypeGuestCanActivate ]
      },

      // Team
      { path: 'team',
        component: ManageComponent,
        resolve: { team: TeamManageResolver },
        canActivate: [ NotLowCanActivate ]
      }
    ]
  },

  {
    path: 'trialline/:trialline_id',
    loadChildren:() => TriallineModule
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class MainRoutingModule {}
