import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from "../../auth/shared/auth.service";
import {env} from "../../../../.env";
import {ToastrService} from 'ngx-toastr';
import * as S3 from 'aws-sdk/clients/s3';
import {FileLibraryResourceService} from "../../main/file-library/shared/file-library-resoruce.service";

declare var jQuery: any;

@Component({
  selector: 'app-shared-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss']
})
export class DropzoneComponent implements OnInit
{
  @Input() isLibrary: boolean = false;
  @Input() triallineId: number = 0;
  @Output() onSuccess = new EventEmitter<any>();
  @Output() onError = new EventEmitter<any>();
  @Output() onQueueChanged = new EventEmitter<number>();
  @Input() path: string;

  config: Object;

  uploadingCount: number = 0;
  editcomp:any;
  tpath: string;
  folder: string;


  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    protected resourceService: FileLibraryResourceService,
  ) {

  }

  ngOnInit()
  {
    this.editcomp=localStorage.getItem("uploadcomponent");

    this.path='';


    this.path = localStorage.getItem("folderpath");

    let self = this;
    this.config = {
      server: this.triallineId ? env.apiUrl +'file'+ '?attach_to_id=' + this.triallineId + '&attach_to=trialline'
        + (this.isLibrary ? '&library=1' : '')
        : env.apiUrl +'file' + (this.isLibrary ? '?library=1' : ''),
      url: this.triallineId ? env.apiUrl +'file'+ '?attach_to_id=' + this.triallineId + '&attach_to=trialline'
        + (this.isLibrary ? '&library=1' : '')
        : env.apiUrl +'file' + (this.isLibrary ? '?library=1' : ''),
      headers: {
        'Authorization': 'Bearer ' + this.authService.token()
      },
      timeout: 180000000000,
      maxFilesize: 100000000,
      acceptedFiles: 'video/mp4,video/mpeg,audio/mpeg,audio/mp3,application/vnd.ms-excel,application/pdf,text/plain,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*,audio/*,application/vnd.oasis.opendocument.text,.jpg,.JPG,.png,.bmp,.xls,.xlsx,.doc,.docx,.odx,.odt,.txt,.mp4,.mp3,.mov,.wma,.aif,.aiff,.aac,.ac3',
      //dictDefaultMessage: '<h3>Drop Files Here</h3><div>or</div><span class="btn btn blue-madison">Select Files</span>',
      dictDefaultMessage: '<span class="btn btn blue-madison ">Upload from Computer</span>',
      dictFileTooBig: "File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.",

      init: function () {
        this.on("addedfile", (file) => {
          console.log('file obj',file)
          console.log('getpath',file.path)
          if(self.authService.userModel.filled_filesize + (file.size / 1048576) > self.authService.userModel.max_filesize) {
            this.removeFile(file);
            self.toastr.error('File-size limit exceeded')
          } else if ((file.size / 1048576) > 15 ) {
            this.removeFile(file);
            self.uploadS3File(file)
            self.authService.addToFileSize(file.size / 1048576);
          } else {
            self.authService.addToFileSize(file.size / 1048576);
          }
        });
        this.on('complete', function (file) {
          self.completeFile(file);
        });
      }
    };
  }

  onUploadError($event) {
    this.toastr.error(DropzoneComponent.extracted($event), 'Error!');
    this.onError.emit($event);
  }

  private static extracted($event) {
    var msg = 'Something went wrong';
    switch (true) {
      case !Array.isArray($event[1]) && typeof $event[1] === 'string':
        msg = $event[1];
        break;
      case !Array.isArray($event[1].file) && typeof $event[1].file === 'string':
        msg = $event[1].file;
        break;
      case !Array.isArray($event[1].file[0]) && typeof $event[1].file[0] === 'string':
        msg = $event[1].file[0];
        break;
    }
    return msg;
  }

  onUploadSuccess($event) {
    this.onSuccess.emit($event[1].body.file);
  }

  start(file: any) {
    this.uploadingCount = this.uploadingCount + 1;
     this.onQueueChanged.emit(this.uploadingCount);
  }

  onSending(data): void {
    var path = localStorage.getItem("folderpath");
    if(path !== null) {
      const file = data[0];
      const formData = data[2];
      formData.append('fp',path);
    }
  }

  complete(file: any) {
    this.uploadingCount = this.uploadingCount - 1;
    this.onQueueChanged.emit(this.uploadingCount);
  }

  autoload() {
    this.path = localStorage.getItem("folderpath");
  }

  completeFile(file){
    let $form = jQuery(file.previewElement).parents('form.dz-started');
    jQuery(file.previewElement).remove('display', 'none');
    jQuery('.dz-message').show();
    if (!$form.find('.dz-preview').length) {
      $form.removeClass('dz-started');
    }
    jQuery(file.previewElement).css('display', 'none');
  }

  uploadS3File(file) {
   let user = this.authService.userModel;
    if (user !== undefined && user.id !== undefined) {
      this.folder = user.id + '/' + Date.now() + '/';
    }
   this.saveS3(file, this.folder + file.name);
  }

  moveToFileLibrary(fileId){
    let user = this.authService.userModel;
    this.resourceService.MoveFileToCompanyLibrary({
      'fileId': fileId,
      'userId': user.id
    }).toPromise().then((response: any) => {  
      console.log('File uploaded to library')
    });
  }

  /**
   *
   * @param file
   * @param folder
   */
  saveS3 (file, folder){ 
    let self = this;
    let status;
    const contentType = file.type;
    
    const params = {
      filePath: folder,
      Body: file,
      ContentType: contentType,
      ACL: 'public-read'
    };

    let size = (file.size / 1048576).toFixed(2);
      jQuery('#myProgress').append('<div id="number_'+ file.size +'" class="dz-preview dz-file-preview dz-processing dz-success">\n' +
        '          <div class="dz-image">\n' +
        '            <img data-dz-thumbnail=""></div>\n' +
        '            <div class="dz-details">\n' +
        '            <div class="dz-size"><span id="file-dz-size" data-dz-size=""><strong>'+ size +'</strong> MB</span></div>\n' +
        '            <div class="dz-filename"><span  data-dz-name="">' + file.name + '</span></div>\n' +
        '          </div>  <div class="dz-progress">\n' +
        '          <img src="assets/images/spinners/Infinity-1.gif" style="width:50px;" />\n' +
        '        </div>  <div class="dz-error-message"><span data-dz-errormessage="">\n' +
        '        </span></div>  <div class="dz-success-mark">\n' +
        '          </div></div>\n' +
        '      </div>');
    jQuery('#myProgress').show();
    // send file to upload in s3 bucket through API
    self.resourceService.uploadToS3(params).toPromise().then((response: any) => {
      console.log('S3 Response', response);
      
      let dataFile = {
        'original_name':file.name,
        'file_size':file.size,
        'mime':file.type,
        'bucket_path':self.folder + file.name,
        'original_extension':self.getExtension(file.name)
      };

      self.resourceService.createFile(dataFile).toPromise().then((response: any) => {
        if (response.id !== undefined) {
          self.toastr.success('Successfully uploaded file.')
          self.completeFile(file);
          self.complete(file);
          self.moveToFileLibrary(response.id);
          self.autoload();
          response['name'] = file.name
          self.onSuccess.emit(response);
          jQuery('#myProgress').html('');
        }
      });
    });
    
    return status;
  }

   getExtension(path) {
    let basename = path.split(/[\\/]/).pop(),
      pos = basename.lastIndexOf(".");
    if (basename === "" || pos < 1) {
      return "";
    }
    return basename.slice(pos + 1);
  }
}
