import {NgModule} from '@angular/core';
import {RouterModule, Routes, PreloadAllModules} from '@angular/router';
import {PageNotFoundComponent} from "./index/page-not-found/page-not-found.component";
import {WelcomeComponent} from "./index/welcome/welcome.component";
import {SampleTriallinesComponent} from "./index/sample-triallines/sample-triallines.component";
import {StarterComponent} from "./layouts/starter/starter.component";
import {AuthComponent} from "./layouts/auth/auth.component";
import {RegisterComponent} from "./auth/register/register.component";
import {LoginComponent} from "./auth/login/login.component";
import {GuestCanActivate} from "./auth/shared/guest.guard";
import {AuthCanActivate} from "./auth/shared/auth.guard";
import {PrivacyPolicyComponent} from "./index/privacy-policy/privacy-policy.component";
import {ConfirmComponent} from "./auth/confirm/confirm.component";
import {SendComponent} from "./auth/reset-password/send/send.component";
import {ResetComponent} from "./auth/reset-password/reset/reset.component";
import {PresentComponent} from "./present/present.component";
import {PresentResolver} from "./present/shared/present.resolver";
import {ForbiddenComponent} from "./index/forbidden/forbidden.component";
import {ConfirmInvitationComponent} from "./auth/confirm-invitation/confirm-invitation.component";
import {ConfirmInvitationAccessComponent} from "./auth/confirm-invitation-access/confirm-invitation-access.component";
import {AdminModeComponent} from "./admin-mode/admin-mode.component";
import {MainModule} from "./main/main.module";
import {TermsOfUseComponent} from "./index/terms-of-use/terms-of-use.component";
import { NurseConsultantsComponent } from './index/nurse-consultants/nurse-consultants.component';
import { PersonalInvestigatorsComponent } from './index/personal-investigators/personal-investigators.component';

const routes: Routes = [
  {path: 'admin-mode/:user_id/:token', component: AdminModeComponent},
  {
    path: '',
    component: StarterComponent,
    canActivate: [GuestCanActivate],
    children: [
      {path: '', component: WelcomeComponent},
      {path: 'v2', component: WelcomeComponent},
      {path: 'sample-triallines', component: SampleTriallinesComponent},
      {path: 'nurse-consultants', component: NurseConsultantsComponent},
      {path: 'personal-investigators', component: PersonalInvestigatorsComponent},
    ]
  },

  {path: 'view/:uuid', component: PresentComponent, resolve: {view: PresentResolver}},

  {
    path: '',
    component: AuthComponent,
    canActivate: [GuestCanActivate],
    children: [
      {path: 'login', component: LoginComponent},
      //{path: 'login', component: WelcomeComponent},
      {path: 'register', component: RegisterComponent},
      {path: 'confirm/email/:token', component: ConfirmComponent},

      // Reset password

      {path: 'reset/password', component: SendComponent},
      {path: 'password/reset', component: ResetComponent}
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {path: 'confirm/invitation/:token', component: ConfirmInvitationComponent},
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {path: 'confirm/invitation-access/:token', component: ConfirmInvitationAccessComponent},
    ]
  },

  {
    path: '',
    canActivate: [AuthCanActivate],
    loadChildren:() => MainModule
  },

  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'terms-of-use', component: TermsOfUseComponent},

  {path: '403', component: ForbiddenComponent},
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
