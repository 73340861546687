<ng-container *ngIf='maintenanceMode == 1; else liveMode'>
    <section class="block_3">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xs-12">
                    <div class="block_3_title">
                        <p class="block_3_title_line_1"> Under Construction </p>
                        <h2 class="block_3_title_line_2"> Website <span>Maintenance</span></h2>
                        <div class="block_1_content_text" style="padding-bottom: 0px;">
                            <p style="font-size: 20pt;">
                                We're updating the system right now. Sorry for the inconvenience.
                            </p>
                            <p style="font-size: 20pt;">
                                Thanks for your patience we'll be back online soon.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-container>
<ng-template #liveMode>
<section class="modal_popup">
    <div class="modal fade" id="popap_form" tabindex="-1" role="dialog" aria-labelledby="popap_formLabel"
         aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">&times;</span></button>
                    <div class="modal-title" id="popap_formLabel">Send Us a Message!</div>
                </div>
                <div class="modal-body">
                    <app-popup-form></app-popup-form>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="block_1" id="home">
    <div class="container-fluid">
        <div class="row block_1_part_1">
            <div class="col-xs-12">
                <div class="block_1_title">
                    <div class="line_1">
                        <h1> Trialline: legal timeline Software </h1>
                    </div>
                    <div class="line_2">
                      <h2> <span>SHOW</span> YOUR <span>CASE</span> AND <span>WIN</span></h2>
                    </div>
                </div>
                <div class="block_1_content">
                    <div class="block_1_content_img"><img src="./assets/img/b1_img_2.png" width="1025" height="459"
                                                          alt="TrialLine - Case Timeline Software for Attorneys"
                                                          class="img-responsive"></div>
                    <!--<div class="block_1_content_text">-->
                    <!--<p><span>TrialLine</span> is a cloud-based mediation, trial presentation and story telling timeline tool for law firms that want maximum insight into their cases. Easily organize and share your case events and <span>related documents with staff, experts, juries, opposing counsel,</span> and other attorneys. <span>TrialLine is completely web-based</span> and does not require any local installation on your computer. It can be accessed and shared through any internet connection on any device. </p>-->
                    <!--</div>-->
                </div>
            </div>
        </div>
        <div class="row block_1_part_2">
            <div class="col-xs-12">
                <div style="text-align: center; margin-top: 20px;">
                    <a [routerLink]="['/']" fragment="contact" class="block_1_content_button_link">
                        <button class="block_1_content_button block_1_content_button_1">Try it Free</button>
                    </a>
                    <a [routerLink]="['/']" fragment="additional_features" class="block_1_content_button_link">
                        <button class="block_1_content_button block_1_content_button_2">view features</button>
                    </a>
                </div>
                <div class="block_1_content_text">
                    <p>
                        TrialLine is a cloud-based mediation, trial presentation and storytelling legal timeline software
                        for law firms and attorneys that want maximum insight into their cases. Create customized legal
                        timelines that clearly and convincingly communicate the important details of your case, and
                        display them in easy to understand, event by event detail. Attach supporting documents directly
                        to each event on the timeline, all file types are supported and easy to upload. With TrialLine,
                        collaboration is simple. Easily organize and share your case events and related documents with
                        staff, experts, juries, opposing counsel, and other attorneys.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="block_2">
    <div class="container">
        <div class="row">
            <div class="col-xs-12">
                <div class="block_2_content">
                    <fieldset>
                        <legend align="center">
                            <div class="video_modal_button">
                                <button type="button" class="video_modal_button_item" data-toggle="modal"
                                        data-target="#modal_video"></button>
                                <div class="modal fade" id="modal_video" tabindex="-1" role="dialog"
                                     aria-labelledby="modal_videoLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close"><span aria-hidden="true">&times;</span>
                                                </button>
                                                <div class="modal-title" id="modal_videoLabel"></div>
                                            </div>
                                            <div class="modal-body">
                                                <iframe allowfullscreen="" frameborder="0" height="600"
                                                        mozallowfullscreen=""
                                                        src="https://player.vimeo.com/video/155416741"
                                                        style="margin-top: 23px;" webkitallowfullscreen=""
                                                        width="100%"></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </legend>
                        <p class="video_modal_button_text">Click to view Video</p>
                        <div class="col-xs-12 block_2_content_text">
                            <p> See how <span>TrialLine</span> Easily organizes
                                <br> CAse <span>Events & Documents</span></p>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="block_3">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <div class="block_3_title">
                    <p class="block_3_title_line_1"> DO MORE FASTER </p>
                    <h2 class="block_3_title_line_2"> Product <span>Features</span></h2>
                    <div class="block_1_content_text">
                        <p>
                            Securely store your files digitally and access and share them through any internet connection, on any device. That means attorneys can work from anywhere at any time, without having to haul around heavy case files. Create customized views to share with different stakeholders, clients and partners. Views include only the information you decide to include, and are password protected. Export medical record summaries in seconds. TrialLine events are easily organized by category, and can be exported as clean chronologies with the push of a button. Also a powerful courtroom tool, TrialLine enables attorneys to control the presentation of their timeline remotely with a tablet. Impressively present the sequence of events for your case, and display documents, images, deposition footage and more, in real time for maximum storytelling impact. TrialLine is completely web-based and does not require any local installation on your computer, and helpful, realtime technical support is available to answer any questions.
                        </p>
                    </div>
                </div>
                <div class="container">
                    <div class="block_3_content">
                        <div class="block_3_slider">
                            <div class="block_3_slider_item flex">
                                <div class="block_3_slider_item_text_block">
                                    <p class="block_3_slider_item_text_block_title"> Easily Organize Case
                                        <br> facts & Documents </p>
                                    <p class="block_3_slider_item_text_block_content"> See your case in clear Event by
                                        Event
                                        detail. Create text summaries, thorough explanations, or theoretical notes.
                                        Attach
                                        all of an Event's relevant documents for easy review and sharing. Create custom
                                        categories for custom Event organization. Create multiple views to share your
                                        TrialLines with different stakeholders, clients and partners. </p>
                                </div>
                                <div class="block_3_slider_item_img_block">
                                  <img src="/assets/img/b3_slider_img_1.png" width="471" height="282"
                                                                                class="img-responsive" alt=""></div>
                                <div class="b_img"><img src="/assets/img/b3_slider_img_b.png" width="471" height="282" alt=""></div>
                            </div>
                            <div class="block_3_slider_item flex">
                                <div class="block_3_slider_item_img_block">
                                  <img src="/assets/img/slider_img_2.png" width="471" height="282"
                                                                                class="img-responsive" alt=""></div>
                                <div class="b_img" style="left: 0px;">
                                  <img src="/assets/img/b3_slider_img_b.png" alt="" width="471" height="282">
                                </div>
                                <div class="block_3_slider_item_text_block">
                                    <p class="block_3_slider_item_text_block_title"> Edit & Share Your
                                        <br> TrialLine on Any Device </p>
                                    <p class="block_3_slider_item_text_block_content"> TrialLine is cloud-based which
                                        means
                                        you can edit and share your TrialLines from any device from anywhere.
                                        Colloborate
                                        with experts, lawyers, and law firm staff from the office or on the road.
                                        Requires
                                        internet connection. </p>
                                </div>
                            </div>
                            <div class="block_3_slider_item flex">
                                <div class="block_3_slider_item_text_block">
                                    <p class="block_3_slider_item_text_block_title"> Export Medical Record
                                        <br> Summaries in Seconds </p>
                                    <p class="block_3_slider_item_text_block_content"> All of your TrialLine events can
                                        be
                                        organized by specific category and exported as clean chronologies. Need a
                                        Medical
                                        Record Summary? Simply export it from TrialLine. Need a history of pre-existing
                                        conditions? Simply export it from TrialLine. TrialLine saves your law firm time
                                        and
                                        money! </p>
                                </div>
                                <div class="block_3_slider_item_img_block"><img src="/assets/img/slider_img_3.png"
                                                                                class="img-responsive" alt=""></div>
                                <div class="b_img"><img src="/assets/img/b3_slider_img_b.png" width="455" height="569" alt=""></div>
                            </div>
                        </div>
                        <div class="block_1_content_buttons">
                            <a [routerLink]="['/']" fragment="contact" class="block_1_content_button_link">
                                <button class="block_1_content_button block_1_content_button_1">Try it Free</button>
                            </a>
                            <a [routerLink]="['/']" fragment="additional_features" class="block_1_content_button_link">
                                <button class="block_1_content_button block_1_content_button_2">view features</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="block_4">
    <div class="container">
        <div class="row">
            <div class="col-xs-12">
                <div class="block_4_title">
                    <p class="block_4_title_line_1">SIDE-BY-SIDE</p>
                    <h2 class="block_4_title_line_2">Product <span>comparison</span></h2>
                </div>
                <div class="block_4_content_part_1">
                    <article class="my_responsive_table">
                        <ul>
                            <li class="block_4_content_part_1_item_names">
                                <button>
                                    <p class="block_4_content_part_1_title_line_1">TimeMAp &trade;</p>
                                    <p class="block_4_content_part_1_title_line_2">lexis nexis</p>
                                </button>
                            </li>
                            <li class="block_4_content_part_1_item_names active">
                                <button>
                                    <p class="block_4_content_part_1_title_line_1"><img
                                            src="/assets/img/small_trialine.png" alt="" width="172" height="45" class="img-responsive"></p>
                                    <!-- <p class="block_4_content_part_1_title_line_2">Law Father</p> -->
                                </button>
                            </li>
                            <li class="block_4_content_part_1_item_names">
                                <button>
                                    <p class="block_4_content_part_1_title_line_1">Case Timeline &trade;</p>
                                    <p class="block_4_content_part_1_title_line_2">Westlaw</p>
                                </button>
                            </li>
                        </ul>
                        <table>
                            <thead>
                            <tr>
                                <th class="hide"></th>
                                <th class="block_4_content_part_1_item_names">
                                    <p class="block_4_content_part_1_title_line_1">TimeMAp &trade;</p>
                                    <p class="block_4_content_part_1_title_line_2">lexis nexis</p>
                                </th>
                                <th class="block_4_content_part_1_item_names default">
                                    <p class="block_4_content_part_1_title_line_1"><img
                                            src="/assets/img/small_trialine.png" width="172" height="45" alt="" class="img-responsive"></p>
                                    <!-- <p class="block_4_content_part_1_title_line_2">Law Father</p> -->
                                </th>
                                <th class="block_4_content_part_1_item_names">
                                    <p class="block_4_content_part_1_title_line_1">Case Timeline &trade;</p>
                                    <p class="block_4_content_part_1_title_line_2">Westlaw</p>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td class="my_responsive_table_item_title">Works on PC</td>
                                <td><span class="yes">&#10004;</span></td>
                                <td class="default"><span class="yes">&#10004;</span></td>
                                <td><span class="yes">&#10004;</span></td>
                            </tr>
                            <tr>
                                <td class="my_responsive_table_item_title">Works on Mac</td>
                                <td>
                                    <div class="no">&#215;</div>
                                </td>
                                <td class="default"><span class="yes">&#10004;</span></td>
                                <td>
                                    <div class="no">&#215;</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="my_responsive_table_item_title">Works on Tablet</td>
                                <td>
                                    <div class="no">&#215;</div>
                                </td>
                                <td class="default"><span class="yes">&#10004;</span></td>
                                <td>
                                    <div class="no">&#215;</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="my_responsive_table_item_title">Link Documents to Events</td>
                                <td><span class="yes">&#10004;</span></td>
                                <td class="default"><span class="yes">&#10004;</span></td>
                                <td><span class="yes">&#10004;</span></td>
                            </tr>
                            <tr>
                                <td class="my_responsive_table_item_title">Email Timelines to Others</td>
                                <td>
                                    <div class="no">&#215;</div>
                                </td>
                                <td class="default"><span class="yes">&#10004;</span></td>
                                <td>
                                    <div class="no">&#215;</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="my_responsive_table_item_title">Share & View Timelines Online</td>
                                <td>
                                    <div class="no">&#215;</div>
                                </td>
                                <td class="default"><span class="yes">&#10004;</span></td>
                                <td>
                                    <div class="no">&#215;</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="my_responsive_table_item_title">Include Video in Events</td>
                                <td>
                                    <div class="no">&#215;</div>
                                </td>
                                <td class="default"><span class="yes">&#10004;</span></td>
                                <td>
                                    <div class="no">&#215;</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="my_responsive_table_item_title">Case Specific Invoicing</td>
                                <td>
                                    <div class="no">&#215;</div>
                                </td>
                                <td class="default"><span class="yes">&#10004;</span></td>
                                <td>
                                    <div class="no">&#215;</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="my_responsive_table_item_title">Realtime Product Support</td>
                                <td>
                                    <div class="no">&#215;</div>
                                </td>
                                <td class="default"><span class="yes">&#10004;</span></td>
                                <td>
                                    <div class="no">&#215;</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="my_responsive_table_item_title">Export Category Specific
                                    <br>Summaries (.doc & .xls)
                                </td>
                                <td>
                                    <div class="no">&#215;</div>
                                </td>
                                <td class="default"><span class="yes">&#10004;</span></td>
                                <td>
                                    <div class="no">&#215;</div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </article>
                </div>
                <div class="block_4_content_part_2">
                    <div class="block_4_part_2_title">
                        <p class="block_4_title_line_1">What our clients say</p>
                        <h2 class="block_4_title_line_2">client <span>testimonials</span></h2>
                    </div>
                    <div class="testimonial_slider">
                        <div class="testimonial_slider_item">
                            <div class="testimonial_slider_item_test">
                                <div class="testimonial_slider_item_content">
                                    <div class="testimonial_slider_img"><img src="/assets/img/testimonial_img_1.png"
                                                                             alt=""></div>
                                    <div class="testimonial_slider_name">
                                        <p class="testimonial_slider_name_line_1">Natasha C.</p>
                                        <p class="testimonial_slider_name_line_2">Paralegal</p>
                                    </div>
                                    <fieldset class="testimonial_hr">
                                        <legend align="center"><span class="testimonial_hr_text">&#8220;</span></legend>
                                    </fieldset>
                                    <div class="testimonial_slider_text">
                                        <p>TrialLine is every legal professionals DREAM when it comes to creating a
                                            clear understanding of the case. From the first time I used TrialLine I was
                                            hooked. I will be using it on every case moving forward.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="testimonial_slider_item">
                            <div class="testimonial_slider_item_content">
                                <div class="testimonial_slider_img"><img src="/assets/img/testimonial_img_3.png" alt="">
                                </div>
                                <div class="testimonial_slider_name">
                                    <p class="testimonial_slider_name_line_1">Sommer L.</p>
                                    <p class="testimonial_slider_name_line_2">Attorney</p>
                                </div>
                                <fieldset class="testimonial_hr">
                                    <legend align="center"><span class="testimonial_hr_text">&#8220;</span></legend>
                                </fieldset>
                                <div class="testimonial_slider_text">
                                    <p>I absolutely recommend TrialLine to any lawyer. We use TrialLine for all aspects
                                        of our case, from organizing documents, to mediation, and through trial. We even
                                        use it to help our clients better understand their cases. </p>
                                </div>
                            </div>
                        </div>
                        <div class="testimonial_slider_item">
                            <div class="testimonial_slider_item_content">
                                <div class="testimonial_slider_img"><img src="/assets/img/testimonial_img_2.png" alt="">
                                </div>
                                <div class="testimonial_slider_name">
                                    <p class="testimonial_slider_name_line_1">Pam M.</p>
                                    <p class="testimonial_slider_name_line_2">Attorney</p>
                                </div>
                                <fieldset class="testimonial_hr">
                                    <legend align="center"><span class="testimonial_hr_text">&#8220;</span></legend>
                                </fieldset>
                                <div class="testimonial_slider_text">
                                    <p>If you have not seen everything TrialLine can do, you need to check it out. It's
                                        user friendly and has amazing features! It's so easy for my team to organize
                                        case facts and sort by categories that we use it to strategize as well as
                                        organize."
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="block_5" id="additional_features">
    <div class="container-fluid">
        <div class="row">
            <div class="block_5_title">
                <h2 class="block_5_title_line_1">Additional <span>Features</span></h2>
            </div>
            <div class="col-xs-6 block_6_img"><img src="/assets/img/b5_img.png" width="742" height="548" alt="" class="img-responsive"></div>
            <div class="col-xs-6 block_6_list">
                <ul class="block_5_ul">
                    <div class="col-xs-7 ul_block">
                        <li>Create Powerful Slide Shows</li>
                        <li>Customizable Color Palletes and Images</li>
                        <li>Set-up Teams and Collaborate</li>
                        <li>Leave Comments and Notes for Other Team Members</li>
                        <li>Create Multiple Views with Filtered Categories</li>
                        <li>Share Online with Mediators &amp; Court</li>
                    </div>
                    <div class="col-xs-5 ul_block">
                        <li>Use in Trial w/Tablet</li>
<!--                        <li>Online &amp; Offline Sync</li>-->
                        <li>Mobile Ready</li>
                        <li>HIPAA Compliant Security</li>
                        <li>Case by Case Invoicing</li>
                        <li>Realtime Technical Support</li>
                    </div>
                </ul>
                <div class="col-xs-12 for_big_dec">
                    <div class="block_1_content_buttons">
                        <a [routerLink]="['/']" fragment="contact" class="block_1_content_button_link">
                            <button class="block_1_content_button block_1_content_button_1">Try it Free</button>
                        </a>
                        <a [routerLink]="['/']" fragment="additional_features" class="block_1_content_button_link">
                            <button class="block_1_content_button block_1_content_button_2">view features</button>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 for_small_dec">
                <div class="block_1_content_buttons">
                    <a [routerLink]="['/']" fragment="contact" class="block_1_content_button_link">
                        <button class="block_1_content_button block_1_content_button_1">Try it Free</button>
                    </a>
                    <a [routerLink]="['/']" fragment="additional_features" class="block_1_content_button_link">
                        <button class="block_1_content_button block_1_content_button_2">view features</button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="block_6">
    <div class="container">
        <div class="row">
            <div class="col-xs-12">
                <div class="block_6_title" id="pricing_plans">
                    <p class="block_6_title_line_1">Pricing & Plans</p>
                    <h2 class="block_6_title_line_2">TrialLine <span>Pricing</span></h2>
                </div>
                <div class="block_6_content_part_1">
                    <article class="my_responsive_table" id="price-table">
                        <table>
                            <thead>
                            <tr>
                                <th class="hide"></th>
                                <th class="block_4_content_part_1_item_names">
                                    <div class="table_pricing_table_top_item">
                                        <div class="table_pricing_name">Month to Month</div>
                                    </div>
                                </th>
                                <th class="block_4_content_part_1_item_names default ">
                                    <div class="table_pricing_table_top_item">
                                        <div class="most_popular"><span>Most Popular</span></div>
                                        <div class="table_pricing_name">Annual</div>
                                    </div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="my_responsive_table_item_title">Per User Charge</td>
                                    <td><span class="bold">$59/month</span></td>
                                    <td class="default"><span class="bold">$41/month*</span></td>
                                </tr>
                            <tr>
                                <td class="my_responsive_table_item_title">Terms of License Agreement</td>
                                <td><span class="bold">Month to Month</span></td>
                                <td class="default"><span class="bold">*Billed Annually - $499 (save $209/year)</span></td>
                            </tr>
                            <tr>
                                <td class="my_responsive_table_item_title">Versions</td>
                                <td>
                                    <span>Online Version</span>
                                </td>
                                <td class="default"><span>Online Version</span></td>
                            </tr>
                            <tr>
                                <td class="my_responsive_table_item_title">Hi-Resolution Poster Print Files</td>
                                <td>
                                    <div>Yes</div>
                                </td>
                                <td class="default"><span>Yes</span></td>
                            </tr>
                            <tr>
                                <td class="my_responsive_table_item_title">.doc and .xls Exports</td>
                                <td>
                                   Yes
                                </td>
                                <td class="default"><span>Yes</span></td>
                            </tr>
                            <tr>
                                <td class="my_responsive_table_item_title">.xls and .cvs Data Imports</td>
                                <td>
                                    <div>Yes</div>
                                </td>
                                <td class="default"><span>Yes</span></td>
                            </tr>
                            <tr>
                                <td class="my_responsive_table_item_title">Add Guest Users</td>
                                <td>
                                    <div>Yes</div>
                                </td>
                                <td class="default"><span>Yes</span></td>
                            </tr>
                            <tr>
                                <td class="my_responsive_table_item_title">Cloud File Storage</td>
                                <td>
                                    <div>100GB**</div>
                                </td>
                                <td class="default"><span>100GB**</span></td>
                            </tr>
                            <tr>
                                <td class="my_responsive_table_item_title">Trial Period</td>
                                <td>
                                    <div class="bold">7-Day Free Trial</div>
                                </td>
                                <td class="default"><span class="bold">7-Day Free Trial</span></td>
                            </tr>
                            <tr>
                            </tr>
                            </tbody>
                        </table>
                    </article>
                    <div class="block_6_table_explanation">
                        <!-- <p> *contact us for 20+ user enterprise agreements.
                            <br> **additional fee based storage is available. </p> -->
                        <p>**additional fee based storage is available.</p>
                    </div>
                </div>
                <div class="block_6_content_part_2" id="contact">
                    <div class="container">
                        <div class="block_6_part_2_title">
                            <div class="block_6_part_2_title_line_1">
                                <p>START YOUR FREE TRIAL TODAY</p>
                            </div>
                            <div class="block_6_part_2_title_line_2">
                                <h2> SIGN-UP FOR A <span>FREE 7 DAY</span> TRIAL
                                    <br> of <span>TrialLine</span> timeline software </h2>
                            </div>
                        </div>
                        <div class="row">
                             <app-register-form></app-register-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="block_7">
    <div class="container">
        <div class="row">
            <div class="col-xs-12">
                <div class="block_7_img"><img src="/assets/img/b1_img_1.png" height="459" width="1025" alt="" class="img-responsive"></div>
                <div class="block_7_title">
                    <div class="block_7_title_line_1">
                        <p>Lets Get Social <lable *ngIf="urlV2Flag"> - Best In Digital Market</lable></p>
                    </div>
                    <div class="block_7_title_line_2">
                        <h2> NEVER MISS <span>AN UPDATE</span></h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-4 social_container">
                <div class="social_width social_width_facebook">
                    <div class="social_title">
                        <p>Like Our facebook Page</p>
                    </div>
                    <div class="social_link">
                        <a target="_blank" href="https://www.facebook.com/trialline" rel="noreferrer"> <span> <span
                                class="social_img_container"> <img src="/assets/img/f_img.png" alt="" width="8" height="18"
                                                                   class="img_social"> </span>
                            <button class="social_facebook">Facebook</button>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-4 social_container">
                <div class="social_width social_width_youtube">
                    <div class="social_title">
                        <p>View Tutorials on YouTube</p>
                    </div>
                    <div class="social_link">
                        <a target="_blank"
                           href="https://www.youtube.com/channel/UCTfgey1X-TBsFqBL_PnUZfA/videos" rel="noreferrer"> <span> <span
                                class="social_img_container"> <img src="/assets/img/y_img.png" alt="" width="19" height="13"
                                                                   class="img_social"> </span>
                            <button class="social_youtube">Youtube</button>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-4 social_container">
                <div class="social_width social_width_twitter">
                    <div class="social_title">
                        <p>Get Product Updates</p>
                    </div>
                    <div class="social_link">
                        <a target="_blank" href="https://twitter.com/trialline" rel="noreferrer"> <span> <span
                                class="social_img_container"> <img src="/assets/img/t_img.png" alt="" width="17" height="14"
                                                                   class="img_social"> </span>
                            <button class="social_twitter">Twitter</button>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</ng-template>