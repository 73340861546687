import {AfterViewInit, Component, NgModule, OnInit, OnDestroy, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Route, Router} from "@angular/router";
import {Subscription} from "rxjs";


declare var $: any;
declare var jQuery: any;

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: [
        // '../shared/styles/starter.css',
        './welcome.component_normalize.css',
        './welcome.component_slick.css',
        './welcome.component_main.css',
        './welcome.component_main_responsive.css'
    ]
})
export class WelcomeComponent  implements OnInit, OnDestroy {
    private subscription: Subscription;
    public urlV2Flag = 0;
    public maintenanceMode : number = 0;

    constructor(
        router: Router,
        protected route: ActivatedRoute,
    ) {

        this.subscription = router.events.subscribe(s => {
            if (s instanceof NavigationEnd) {
                const tree = router.parseUrl(router.url);
                if (tree.fragment) {
                    const element = document.querySelector("#" + tree.fragment);
                    if (element) {
                        element.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
                    }
                }
            }
        });
    }

    ngOnInit(): void {
        console.log('this.route.snapshot',this.route.snapshot)
        if (typeof this.route.snapshot.url[0] !== 'undefined') {
            if(this.route.snapshot.url[0].path == 'v2') {
                this.urlV2Flag = 1;
            }
        }
    }


    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit() {
        $('.block_3_slider').slick({
            dots: false,
            fade: false,
            arrows: true,
            infinite: false,
            autoplay: true,
            autoplaySpeed: 3000
        });
        $('.testimonial_slider').slick({
            centerMode: true,
            slidesToShow: 3,
            dots: false,
            fade: true,
            arrows: true,
            responsive: [
                {
                    breakpoint: 865,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        Padding: '30px',
                        dots: false,
                        arrows: true,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        Padding: '40px',
                        dots: false,
                        arrows: true,
                    }
                }
            ]
        });
        if (window.innerWidth < 767) {
            $(".nav_close_link").click(function () {
                $('div').removeClass('in');
            });
        }
        $(".my_responsive_table ul").on("click", "li", function () {
            var pos = $(this).index() + 2;
            $("tr").find('td:not(:eq(0))').hide();
            $('td:nth-child(' + pos + ')').css('display', 'table-cell');
            $("tr").find('th:not(:eq(0))').hide();
            $('li').removeClass('active');
            $(this).addClass('active');
        });

        var mediaQuery = window.matchMedia('(min-width: 640px)');

        mediaQuery.addListener(doSomething);

        function doSomething(mediaQuery) {
            if (mediaQuery.matches) {
                $('.sep').attr('colspan', 5);
            } else {
                $('.sep').attr('colspan', 2);
            }
        }

        doSomething(mediaQuery);
    }
}