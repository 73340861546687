<div id="contact-edit-modal" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button"   aria-label="Close" (click)="closePopup()" class="close"><span
          aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">{{ (contact.id ? '' : 'Create new contact ') + (contact.name ? contact.name : '') }}</h4>
      </div> 
      <form #contactForm="ngForm" role="form-horizontal" class="event-form" (submit)="saveContact(contactForm)">
        <div class="modal-body portlet-body">
          <div class="">
            <div class="row">
              <div id="details-contact" class="panel-collapse">
                <div class="panel-body">
                  <div class="form-body">
                    <div class="form-horizontal">
                      <h5>Category*</h5>
                      <div class="form-group">
                        <label for="facilityAddress" class="col-sm-3 control-label">Choose contact category</label>
                        <div class="col-sm-9">
                          <ng-select [items]="contactCategories"
                                     bindLabel="name"
                                     bindValue="id"
                                     [multiple]="true"
                                     [(ngModel)]="contactCategoriesSelected"
                                     [ngModelOptions]="{standalone: true}">
                          </ng-select>
                          <span class="help-block" *ngIf="!contactCategoriesSelected.length" style="color: red;">
                            Contact category is required
                          </span>
                        </div>
                      </div>
                        <app-trialline-event-contact-modal-categories [triallineId]="triallineId" [color]="background"
                                                                (onCreated)="addCategory($event)"
                                                                      (onBackground)="onChangeBackground($event)" >
                        </app-trialline-event-contact-modal-categories>
                      <h5>Provider Details</h5>
                      <div class="form-group">
                        <label for="contactName" class="col-sm-3 control-label">Contact Name*</label>
                        <div class="col-sm-9">
                          <input type="text" name="name" [ngModel]="contact.name" class="form-control"
                                 (keyup)="categoryNameChanged(contact.name)" (change)="categoryNameChanged(contact.name)"
                                 id="contactName" placeholder="Name">      
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="contactPhone" class="col-sm-3 control-label">Contact Phone</label>
                        <div class="col-sm-9">
                          <input type='tel' name="phone" [ngModel]="contact.phone"
                                 class="form-control" id="contactPhone" placeholder="Phone" maxlength="15">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="contactEmail" class="col-sm-3 control-label">Contact Email</label>
                        <div class="col-sm-9">
                          <input type="email" name="contact_email" class="form-control"
                                 id="contactEmail"
                                 [ngModel]="contact.email"
                                 placeholder="Email">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="contactWebsite" class="col-sm-3 control-label">Contact Website</label>
                        <div class="col-sm-9">
                          <input type="email" name="website" class="form-control"
                                 id="contactWebsite"
                                 [ngModel]="contact.website"
                                 placeholder="Website">
                        </div>
                      </div>
                      <h5>Contact Location Details</h5>
                      <div class="form-group">
                        <label for="facilityName" class="col-sm-3 control-label">Location Name</label>
                        <div class="col-sm-9">
                          <input type="text" name="facility_name" [ngModel]="contact.facility_name"
                                 class="form-control" id="facilityName" placeholder="Location Name">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="facilityAddress" class="col-sm-3 control-label">Location Address</label>
                        <div class="col-sm-9">
                          <input type="text" name="address" [ngModel]="contact.address" class="form-control"
                                 id="facilityAddress" placeholder="Address">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="facilityAddress" class="col-sm-3 control-label">Suite Number</label>
                        <div class="col-sm-9">
                          <input type="number" name="office_number" [ngModel]="contact.office_number" class="form-control"
                                 id="facilityOfficeNumber" placeholder="Office number">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="facilityAddress" class="col-sm-3 control-label">City</label>
                        <div class="col-sm-9">
                          <input type="text" name="city" [ngModel]="contact.city" class="form-control" id="facilityCity"
                                 placeholder="City">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="facilityAddress" class="col-sm-3 control-label">State</label>
                        <div class="col-sm-9">
                          <input type="text" name="state" [ngModel]="contact.state" class="form-control"
                                 id="facilityState" placeholder="State">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="facilityAddress" class="col-sm-3 control-label">Zip code</label>
                        <div class="col-sm-9">
                          <input name="postal_code" [ngModel]="contact.postal_code" class="form-control"
                                 id="facilityPostalCode"
                                 type="text"
                                 placeholder="Postal code">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="facilityAddress" class="col-sm-3 control-label">Notes</label>
                        <div class="col-sm-9">
                          <ckeditor  #ckeditor1 (change)="onCKChange($event)"
                                     [ngModel]="contact.notes" name="notes"  [config]="{ versionCheck: false }" debounce="500">
                          </ckeditor>
                        </div>
                      </div>
                      <h5>Upload Contact Photo</h5>
                      <div class="form-group">
                        <div class="col-sm-9">
                          <div class="note-small mb-1">Click on image to change the thumbnail</div>
                          <label for="contact_thumbnail_input">
                            <input type="file" ng2FileSelect [uploader]="uploader"
                                   id="contact_thumbnail_input" style="display: none;">
                            <div class="fileinput fileinput-new upload-box">
                              <div class="fileinput-preview thumbnail tile upload-files" style="width: 210px; position: relative;">
                                <div class="tile-body">
                                  <img #thumbnail_contact [attr.src]="contact_thumbnail"
                                       class="img-responsive" width="200px" alt="thumbnail_contact">
                                  <a *ngIf="showContactDeleteIcon(thumbnail_contact.src)"
                                     (click)="deleteContactThumbnail(); $event.preventDefault()"
                                     id="delete-background-contact" class="remove-background">&times;</a>
                                </div>
                              </div>
                            </div>
                          </label>
                          <div *ngIf="thumbnail_visible === null">Some thumbnails are visible and some - not</div>
                          <div class="mt-checkbox-inline">
                            <label class="mt-checkbox">
                              <input name="thumbnail_visible" id='contact_thumbnail_visible'
                                     [(ngModel)]="contact.thumbnail_visible" type="checkbox">
                              Show thumbnail
                              <span></span>
                            </label>
                          </div>
                          <div class="progress" style="height: 3px; width: 200px; margin: 0">
                            <div class="progress-bar" role="progressbar" [style.width]="uploader.progress + '%'"></div>
                          </div>
                        </div>
                      </div>
                      <h5>Attach</h5>
                      <div class="attach-wrap">
                        <input type="file" class="35645" ng2FileSelect [uploader]="privateUploader"
                               (onFileSelected)="fileSelected($event)" multiple/><br/>
                        <table class="table">
                          <thead>
                          <tr>
                            <th>File name</th>
                            <th>Progress</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngFor="let file of contact?.private_files">
                            <td>
                              <input class="form-control input-sm" type="text" value="{{file.file.title}}"
                                     #attachedText placeholder="Attach Text">
                            </td>
                            <td>
                              <span><i class="glyphicon glyphicon-ok"></i></span>
                            </td>
                            <td nowrap>
                              <a [attr.href]="apiPath + 'filesystem/' + file.file.encrypt_id + '/download'"
                                 class="btn btn-success btn-xs">
                                <span class="glyphicon glyphicon-download"></span> Download
                              </a>
                              <a (click)="removeFile(file.file)"
                                 class="btn btn-danger btn-xs">
                                <span class="glyphicon glyphicon-remove"></span> Delete
                              </a>
                              <button type="button" class="btn btn-success btn-xs"
                                      (click)="rename(file.file_id,
                                       attachedText.value)">
                                <span class="glyphicon glyphicon-upload"></span> Rename
                              </button>
                            </td>
                          </tr>
                            <tr *ngFor="let item of listUploadFiles">
                              <td>
                                <input class="form-control input-sm" type="text" #attachText
                                       value="{{item.some.name}}">
                              </td>
                              <td>
                                <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
                              </td>
                              <td nowrap>
                                <a [attr.href]="apiPath + 'filesystem/' + item.file.file.encrypt_id + '/download'"
                                   class="btn btn-success btn-xs">
                                  <span class="glyphicon glyphicon-download"></span> Download
                                </a>
                                <a (click)="removeFile(item.file)"
                                   class="btn btn-danger btn-xs">
                                  <span class="glyphicon glyphicon-remove"></span> Delete
                                </a>
                                <button type="button" class="btn btn-success btn-xs"
                                        (click)="upload(item, attachText.value)" >
                                  <span class="glyphicon glyphicon-upload"></span> Rename
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="closePopup()">Close</button>
          <button type="submit" class="btn btn-primary">{{ contact.id ? 'Save changes' : 'Create' }}</button>
          <a (click)="deleteContact()" class="btn red btn-delete pull-left"
             data-confirm="Warning! If you delete this Event you won't be able to retrieve it. Are you sure you want to proceed?"
             *ngIf="contact.id">
            Delete
          </a>
        </div>
      </form>
    </div>
  </div>
</div>
