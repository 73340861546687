
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {RestService} from "../../../rest/shared/rest.service";
import {FileModel} from "./file.model";

@Injectable()
export class FileLibraryResourceService
{
  constructor(
    public http: RestService,
    private httpClient: HttpClient,
  ) {}

  getFiles(): Observable<any>
  {
    return this.http.get('file/my').pipe(map((response: any) => {
      let body = response.body;

      return body.files;
    }));

  }

  uploadThumbnail(data) {
    return this.http.post('file/thumbnail/', data).pipe(map(
      r => { return r.body; }
    ));
  }

  getTriallineFiles(trialline_id, data): Observable<any> {

    return this.http.post('file/trialline/' + trialline_id, data).pipe(map(
      r => { return r.body; }
    ));
  }

  deleteFile(file: FileModel): Observable<any> {
    return this.http.delete('file/' + file.id).pipe(map(
      r => r.body
    ));
  }

  deleteFileFromEvent(file: FileModel): Observable<any> {
    return this.http.post('/file/delete/' + file.id, file).pipe(map(
      r => r.body
    ));
  }

  deleteFileFromEventNew(file: FileModel, event): Observable<any> {
    let eventId = event ? event.id : ''
    let fileId = file.file_id ? file.file_id : file.id;
    return this.http.post('/file/delete_event/' + fileId, {eventId: eventId}).pipe(map(
      r => r.body
    ));
  }

  deleteFileFromContact(file: FileModel, contact): Observable<any> {
    let fileId = file.file_id ? file.file_id : file.id;
    let contactId = contact ? contact.id : ''
    return this.http.post('/file/delete_contact/' + fileId, {contactId}).pipe(map(
      r => r.body
    ));
  }

  logFile(file: FileModel): Observable<any> {
      return this.http.get('file/log/' + file.id).pipe(map(
          r => r.body
      ));
  }

  cloudFile(file: FileModel): Observable<any> {

    return this.http.post('file/' + file.id + '/library', []).pipe(map((response: any) => {
      let body = response.body;

      return body.status;
    }));

  }

  updateFile(file: FileModel, data): Observable<any> {

    return this.http.put('file/' + file.id, data).pipe(map((response: any) => {
      let body = response.body;

      return body.file;
    }));

  }

  backgrounds(): Observable<any> {
    return this.http.get('file/backgrounds').pipe(map((response: any) => {
      let body = response.body;

      return body.backgrounds;
    }));
  }


  getFolders(data) {
    return this.http.post('file/getFolder',data);
  }

  CreateEmptyFolder(data) {

    return this.http.post('file/CreateFolder',data);
  }

  RemoveFile(data) {
    return this.http.post('file/DeleteFile',data);
  }

  MoveFileToCompanyLibrary(data) {
    return this.http.post('file/MoveFileToCompanyLibrary',data);
  }

  AddcompanyFile(data) {

    return this.http.post('file/OnCloud',data);
  }

  renameFoldersapi(data) {

     return this.http.post('file/FileRename',data);
  }

  renameFolderstable(data)
  {

      return this.http.post('file/FolderRename',data);
  }

  deletefiles(data) {

    return this.http.post('file/FileDelete',data);
  }

  deletefolders(data) {

    return this.http.post('file/DeleteFolder',data);
  }

  insertdummy(data)
  {

    return this.http.post('/file/DummyFile',data);
  }

  zipfiles(data)
  {
    return this.http.post('/file/ZipFileDownload',data);
  }

  /* Company File library */
  getcFolders(data)
  {
    return this.http.post('/file/companyfolder',data);
  }

  CreatecEmptyFolder(data)
  {
    return this.http.post('/file/createCompanyFolder',data);
  }

  deletecfiles(data) {

    return this.http.post('/file/deleteCompanyFile',data);
  }

  createFile( data): Observable<any> {
    return this.http.post('file/createFile', data).pipe(map((response: any) => {
      let body = response.body;

      return body.file;
    }));
  }
  
  uploadToS3(data): Observable<any> {

      // Create FormData to hold the file
      const formData = new FormData();
      formData.append('file', data.Body, data.Body.filename);
      formData.append('folderpath', data.filePath);
      
      return this.http.post('file/uploadToS3', formData).pipe(map((response: any) => {
        let body = response.body;
        return body.file;
      }));

  }

}
